<template>
    <div class="w-full dF fC f1 px-4 pb-4" style="background-color: #F7F5F9;">
        <EditDrawer @update="update" />
        <EditAppointmentModal @cancelEdit="cancelEditAppointment" @updated="updatedAppointment" />
        <opportunityDetails :time="refresh" />
        <EditTaskModal @cancelEdit="cancelEdit" />
        <lostWonModal :opportunity="lostWon" @closed="closeLostWon" @won="updateWon" @lost="updateLost" @archive="archiveOpportunity" />
        <EditNoteModal />

        <!-- Sales Pipeline -->
        <template v-if="opportunities && opportunities.length && !showExpandOpportunity">
            <div class="dF jSB">
                <h4 class="mb-3 mt-3">{{sidebarSelection}}</h4>
                <div class="dF aC">
                    <a-popover overlayClassName="popoverStyle" class="mr-4" placement="bottom" v-if="displayType == 'list'">
                        <template slot="content">
                            <div v-if="sort != 'date'" @click="sortOpportunity('date')" style="cursor:pointer; padding:7.5px 15px" class="dF aC"><div>Sort by:</div>&nbsp;<div style="color:#000">Date</div></div>
                            <div v-if="sort != 'name'" @click="sortOpportunity('name')" style="cursor:pointer; padding:7.5px 15px" class="dF aC"><div>Sort by:</div>&nbsp;<div style="color:#000">Name</div></div>
                            <div v-if="sort != 'stage'" @click="sortOpportunity('stage')" style="cursor:pointer; padding:7.5px 15px" class="dF aC"><div>Sort by:</div>&nbsp;<div style="color:#000">Deal Stage</div></div>
                            <div v-if="sort != 'value'" @click="sortOpportunity('value')" style="cursor:pointer; padding:7.5px 15px" class="dF aC"><div>Sort by:</div>&nbsp;<div style="color:#000">Value</div></div>
                            <div v-if="sort != 'owner'" @click="sortOpportunity('owner')" style="cursor:pointer; padding:7.5px 15px" class="dF aC"><div>Sort by:</div>&nbsp;<div style="color:#000">Sales Owner</div></div>
                        </template>
                        <div style="background-color:#FFF; border:1px solid var(--off-white-dark); cursor:pointer; border-radius:6px;" class="dF aC py-2 px-3"><div>Sort by:</div>&nbsp;<div class="mr-2" style="color:#000">{{sort == 'date' ? 'Date': sort == 'name' ? 'Name' : sort == 'stage' ? 'Deal Stage' : sort == 'value' ? 'Value' : sort == 'owner' ? 'Sales Owner' : ''}}</div><a-icon type="caret-down" /></div>
                    </a-popover>
                    <div @click="displayType = 'toggle'" class="dF aC jC p-1" :style="displayType == 'toggle' ? 'background-color:var(--orange)':'background-color:#D3D0D6'" style="cursor:pointer; border-radius:3px"><i style="color:white; cursor: pointer; font-size:20px" class="fa fa-list-ul" /></div>
                    <i @click="displayType = 'list'" :style="displayType == 'list' ? 'color:var(--orange)':'color:#D3D0D6' " style="cursor: pointer; font-size:27.5px" class="ml-3 fa fa-th-list" />
                </div>
            </div>
            <template v-if="displayType == 'toggle'">
                <div class="w-full h-full">
                    <div v-if="settings.options && settings.options.stages && settings.options.stages.list" class="dF w-full h-full" style="overflow: scroll; height: 92%;">
                        <div class="mr-3" style="width: 380px" v-for="(stage,stageI) in settings.options.stages.list" :key="stage+stageI">
                            <div class="pipelineColumns" :style="`border-top: 5px solid ${stage.color};`" @drop='onDrop($event, stage.id)' @dragover.prevent @dragenter.prevent>
                                <h6 class="mb-4">{{stage.name + ` (${listOne[stage.id].length})`}}</h6>
                                <div class="mb-3" style="cursor: pointer;" v-for="(opportunity,opportunityI) in listOne[stage.id]" :key="opportunity+opportunityI" draggable @dragstart='startDrag($event, opportunity)'>
                                    <salesCard @marklost="markLost" @markwon="markWon" @archive="markArchive" @deleteopp="deleteOpportunity" @update="updateOpportunity" :obj="opportunity" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <OpportunityTable :sort="sort" @marklost="markLost" @markwon="markWon" @archive="markArchive" @deleteopp="deleteOpportunity" />
            </template>
        </template>

        <template v-else>
            <h4 class="mb-3 mt-3">{{sidebarSelection}}</h4>
            <EmptyApp :text="`You don\'t have any opportunities`" :img="require('@/assets/emptyIcons/opportunities.png')" />
        </template>

    </div>
</template>

<script>
    import BHLoading from 'bh-mod/components/common/Loading'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    import salesCard from '@/components/common/salesCard'
    import opportunityDetails from '@/components/common/opportunityDetails'
    import menuList from '@/components/common/menuList'
    import lostWonModal from '@/components/common/lostWonModal'
    import searchContact from '@/components/common/searchContact'
    import moment from 'moment'
    import EditNoteModal from '@/components/common/EditNoteModal'
    import EditTaskModal from '@/components/contacts/EditTaskModal'
    import EditAppointmentModal from '@/components/contacts/EditAppointmentModal'
    import OpportunityTable from '@/components/contacts/OpportunityTable'
    import EditDrawer from '@/components/contacts/EditDrawer'
    export default {
        components:{
            BHLoading,EmptyApp,salesCard,opportunityDetails,menuList,lostWonModal,searchContact,EditNoteModal,EditTaskModal,EditAppointmentModal,OpportunityTable,EditDrawer
        },
        data() {
            return {
                sort:'date',
                activeTab:0,
                saveLoading:false,

                expandedOpportunity:{},
                lostWon:{
                    type:'',
                    visible:false,
                    opportunity:{},
                },

                userSource:[],

                refresh:0,
                test:0,
                displayType:'toggle',
                events: [
                    {
                        date:'2020-01-24T22:22:50.363Z',
                        logNote:'You added this contact',
                    },
                    {
                        date:'2020-01-24T22:22:50.363Z',
                        logNote:'You added a note'
                    },
                    {
                        date:'2020-01-23T22:22:50.363Z',
                        logNote:'Lorem ipsum dolor sit amet, consectetuer adipiscing',
                    },
                    {
                        date:'2020-01-23T20:22:50.363Z',
                        logNote:'Lorem ipsum dolor sit amet, consectetuer adipiscing',
                    },
                    {
                        date:'2020-01-23T21:22:50.363Z',
                        logNote:'Lorem ipsum dolor sit amet, consectetuer adipiscing',
                    },
                    {
                        date:'2020-01-23T19:22:50.363Z',
                        logNote:'Lorem ipsum dolor sit amet, consectetuer adipiscing',
                    },
                    {
                        date:'2020-01-24T22:22:50.363Z',
                        logNote:'Lorem ipsum dolor sit amet, consectetuer adipiscing',
                    },
                    {
                        date:'2020-01-25T22:22:50.363Z',
                        logNote:'Lorem ipsum dolor sit amet, consectetuer adipiscing',
                    },
                ],

                rules: {
                    name: [
                        { required: true, message: 'Please input a name', trigger: 'blur' },
                    ],
                    contact: [
                        { required: true, message: 'Please select a contact', trigger: 'blur' },
                    ],
                    closeDate: [
                        { required: true, message: 'Please enter a close date', trigger: 'blur' },
                    ],
                    stage: [
                        { required: true, message: 'Please select a stage', trigger: 'blur' },
                    ],
                    dealValue: [
                        { required: true, message: 'Please enter a deal value', trigger: 'blur' },
                    ],
                },
            }
        },
        watch:{
            showExpandOpportunity(val){
                if (val){
                    this.userSource = []
                    this.expandedOpportunity = JSON.parse(JSON.stringify(this.expandOpportunity.opportunity))
                    this.activeTab = 0
                    let list = []
                    this.expandedOpportunity.owners.forEach( user => {
                        list.push(user.id)
                        let index = this.users.findIndex( x => x.id == user.id)
                        this.userSource.push(this.users[index])
                    })
                    this.expandedOpportunity.owners = list

                    this.expandedOpportunity.closeDate = this.moment(this.expandedOpportunity.closeDate).format()
                    console.log('FINAL EXPANDED OPPORTUNITY', this.expandedOpportunity)
                }
            },
        },
        computed:{
			dateFormat(){
				return this.$store.state.contacts.allSettings.user && this.$store.state.contacts.allSettings.user.options && this.$store.state.contacts.allSettings.user.options.regional && this.$store.state.contacts.allSettings.user.options.regional.dateFormat ? this.$store.state.contacts.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
			},
            lostReasons() {
                if (this.$store.state.contacts.allSettings.app.options.lostReasons) return this.$store.state.contacts.allSettings.app.options.lostReasons
                else return {}
            },
            stages() {
                if (this.$store.state.contacts.allSettings.app && this.$store.state.contacts.allSettings.app.options && this.$store.state.contacts.allSettings.app.options.stages && this.$store.state.contacts.allSettings.app.options.stages.list) {
                    return this.$store.state.contacts.allSettings.app.options.stages.list
                } else {
                    return []
                }
            },
            listOne(){
				console.log('LIST ONE UPDATED')
                let refresh = this.refresh
                let obj = {}
				this.settings.options.stages.list.forEach(x => {
					if (x) {
						if (x.id == 'transaction') {
							obj[x.id] = this.opportunities.filter(opp => opp.transaction && opp.transaction.length && opp.transaction != '' && !opp.archived && opp.stage != 'won' && opp.stage != 'lost')
						}
						else if (x.id == 'won') {
							let list = this.opportunities.filter(opportunity => opportunity.stage === x.id && !opportunity.archived)
							list.forEach(item => {
								item.probability = 100
							})
							obj[x.id] = list
						}
						else if (x.id == 'lost') {
							let list = this.opportunities.filter(opportunity => opportunity.stage === x.id && !opportunity.archived)
							list.forEach(item => {
								item.probability = 0
							})
							obj[x.id] = list
						}
						else {
							obj[x.id] = this.opportunities.filter(opportunity => opportunity.stage === x.id && !opportunity.archived && (!opportunity.transaction || opportunity.transaction == ''))
						}
					}
				})
                console.log('LISTONE', obj)
                return obj
            },
            opportunities(){
				let refresh = this.refresh
				console.log('OPPORTUNITIES UPDATED')
                let opportunities = JSON.parse(JSON.stringify(this.$store.state.contacts.opportunities))
                console.log('USERS', this.users)
				opportunities = opportunities.filter(x => {
                    if (x.visibility != 'everyone' && x.visibility != null){
                        let userFound = false
                        x.owners.forEach(y => {
                            if (y.id == this.currentUser.user.id) userFound = true
                        })
                        return userFound || this.$p == 50
                    } else {
                        return true
                    }
                })
                console.log('OPPORTUNITIES', opportunities)
                return opportunities.sort((a, b) => moment(b.updatedAt).format('X') - moment(a.updatedAt).format('X'))
            },
            tasks(){
                let tasks = JSON.parse(JSON.stringify(this.$store.state.contacts.tasks))
                if (tasks && tasks.length) tasks = tasks.filter( x => x.relatedType == 'opportunity' && x.opportunity.id == this.expandOpportunity.opportunity.id)
                if (tasks && tasks.length){
                    tasks.sort((a,b) => {
                        return a.dueDate - b.dueDate
                    })
                }
                return tasks
            },
            appointments(){
                let refresh = this.test
                let appointments = JSON.parse(JSON.stringify(this.$store.state.contacts.appointments))
                if (appointments && appointments.length) appointments = appointments.filter( x => x.relatedType == 'opportunity' && x.opportunity.id == this.expandOpportunity.opportunity.id)
                if (appointments && appointments.length){
                    appointments.sort((a,b) => {
                        return a.date - b.date
                    })
                }
                return appointments
            },
            createDates(){
                let dates = {}
                this.events.forEach( x => {
                    if (!dates.hasOwnProperty(`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}`)){
                        let obj = {
                            date:`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`,
                            sorted:this.sortDates(`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`)
                        }
                        dates[obj.date] = obj
                    }
                })
                console.log('ALL DATES', dates)
                let sorted = Object.values(dates).sort( (a,b) => {
                    let {date:date1} = a
                    let {date:date2} = b

                    date1 = new Date(date1).getTime()
                    date2 = new Date(date2).getTime()

                    return date1 > date2 ? -1 : 1
                })
                console.log('SORTED DATESSSS', sorted)

                return sorted
            },
            settings(){
                console.log('ALLSETTINGS APP', this.$store.state.contacts.allSettings.app)
                return this.$store.state.contacts.allSettings.app
            },
            showExpandOpportunity(){
                return this.$store.state.contacts.expandOpportunity.visible
            },
            expandOpportunity(){
                let expand = this.$store.state.contacts.expandOpportunity
                expand.opportunity.notes.sort((a,b) => {
                    return (this.moment(b.updatedAt).format('X')*1000) - (this.moment(a.updatedAt).format('X')*1000)
                })
                return expand
            },
            settingsMenu(){
                let items = [
                    {label: 'General Information', ant: 'info-circle'},
                    {label: 'Notes', fe: 'message'},
                    {label: 'Tasks', fe: 'user-check'},
                    {label: 'Appointments', ant: 'calendar'},
                    {label: 'Form Submissions', fe: 'align-justify'},
                    {label: 'Timeline', ant: 'clock-circle'},
                ]
                return items
            },
            sidebarSelection(){
                return this.$store.state.sidebarSelection
            },
            tags(){
                return this.$store.state.contacts.tags
            },
            currentUser(){
                console.log('CURRENT USER', this.$store.state.user)
                return this.$store.state.user
            },
            searchOBJ(){
                return this.$store.state.contacts.searchOBJ
            },
            resultLength(){
                return this.$store.state.contacts.resultLength
            },
            instance(){
                return this.$store.state.instance
            },
            users(){
                return this.$store.state.contacts.users
            },
        },
        methods:{
            sortOpportunity(type) {
                if (this.sort != type) {
                    this.sort = type
                }
            },
            update() {
				console.log('Updating...')
                this.refresh = Date.now()
            },
            getStage(stageId) {
                let find = this.stages.find(x => x.id == stageId)
                if (find) {
                    return find.name
                } else return ''
            },
            moment,
            updateOpportunity(obj){
                console.log('UPDATE OPPORTUNITY RAN', obj)
                let index = this.opportunities.findIndex(item => item.id == obj.id)
                this.opportunities[index] = obj
                this.refresh = Date.now()
            },
            deleteOpportunity(obj){
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Opportunities')
                }
                console.log('DELETE OPPORTUNITY RAN', obj)
                let self = this
                this.$confirm({
                    title: "Delete Opportunity",
                    content: h => <div>Do you want to delete <b>{obj.name}</b>? All information related to them will be lost.</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        // self.tasks.forEach( task => {
                        //     if (task.relatedType == 'opportunity' && task.opportunity.id == obj.id){
                        //         self.$api.delete(`/tasks/:instance/${task.id}`).then( ({data}) => {
                        //             self.$store.commit('DELETE_TASK', data)
                        //         })
                        //     }
                        // })
                        // self.appointments.forEach( appointment => {
                        //     if (appointment.relatedType == 'opportunity' && appointment.opportunity.id == obj.id){
                        //         self.$api.delete(`/appointments/:instance/${appointment.id}`).then( ({data}) => {
                        //             self.$store.commit('DELETE_APPOINTMENT', data)
                        //         })
                        //     }
                        // })
                        self.loading = true
                        self.$api.delete(`/opportunities/:instance/${obj.id}`).then( ({data}) => {
                            console.log('DATAA COMING BACK', data)
                            self.loading = false
                            self.$store.commit('UPDATE_OPPORTUNITIES', data)
                            // let index = self.opportunities.findIndex(item => item.id == data.id)
                            // console.log('OPPORTUNITY FOUNDD', index)
                            // self.opportunities.splice(index,1)
                            self.refresh = Date.now()
                        }).catch(err => {
							self.loading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            archiveOpportunity(obj,note){
                console.log('ARCHIVE OPPORTUNITY RAN', obj)
                let opportunity = JSON.parse(JSON.stringify(obj))
                let newNote = JSON.parse(JSON.stringify(note))
                opportunity.archived = true

                if (note.subject || note.content){
                    console.log('NOTE HAS SUBJECT OR CONTENT')
                    this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,newNote).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                        this.$api.put(`/opportunities/:instance/${opportunity.id}`,opportunity).then( ({data}) => {
                            console.log('DATAA COMING BACK', data)
                            let index = this.opportunities.findIndex(item => item.id == data.id)
                            console.log('OPPORTUNITY FOUNDD', index)
                            this.opportunities[index].closeDate = data.closeDate
                            this.opportunities[index].archived = true
                            this.refresh = Date.now()
                            this.closeLostWon()
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }).catch( err => {
                        console.log('ERORR', err)
                        if (err.response.data.error == 'Not Found'){
                            this.$message.error('Opportunity Not Found!')
                        }
                        this.loading = false
                    })
                } else {
                    this.$api.put(`/opportunities/:instance/${opportunity.id}`,opportunity).then( ({data}) => {
                        console.log('DATAA COMING BACK', data)
                        let index = this.opportunities.findIndex(item => item.id == data.id)
                        console.log('OPPORTUNITY FOUNDD', index)
                        this.opportunities[index].closeDate = data.closeDate
                        this.opportunities[index].archived = true
                        this.refresh = Date.now()
                        this.closeLostWon()
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
            },
            noteBy(user){
                let index = this.users.findIndex( x => x.id == user)
                return this.users[index].firstName[0].toUpperCase() + this.users[index].lastName[0].toUpperCase()
            },
            editNote(obj){
                console.log('EDITNOTE CLICKED', obj)
                let note = {}
                note = {
                    visible:true,
                    note:obj,
                    objId:this.expandOpportunity.opportunity.id,
                    type:'opportunity'
                }
                this.$store.commit('EDIT_NOTE',note)
            },
            deleteNote(obj){
                console.log('DELETE NOTE CLICKED', obj)
                let self = this
                this.$confirm({
                    title: "Delete Note",
                    content: h => <div>Do you want to delete this Note?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.$api.delete(`/comments/:instance/${obj.id}`).then( ({data}) => {
                            console.log('just delete', data)
                            data.user = data.user.id
                            let newData = {
                                id:self.expandOpportunity.opportunity.id,
                                note:data
                            }
                            self.$store.commit('DELETE_OPPORTUNITY_NOTE', newData)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            updatedAppointment(){
                console.log('UPDATE APPOINTMENT RAN')
                this.test = Date.now()
            },
            editAppointment(item){
                console.log('EDIT APPOINTMENT CLICKED', item)
                this.$store.commit('EDIT_APPOINTMENT', item)
            },
            deleteAppointment(item){
                console.log('DELETE APPOINTMENT CLICKED', item)
                if (this.$p < 40){
                    return this.$message.error('You do not have permission to delete Appointments')
                }
                let self = this
                this.$confirm({
                    title: "Delete Appointment",
                    content: h => <div>Do you want to delete this Appointment?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.$api.delete(`/appointments/:instance/${item.id}`).then( ({data}) => {
                            console.log('just delete', data)
                            self.$store.commit('DELETE_APPOINTMENT', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            editTask(item) {
                console.log('EDIT TASK CLICKED', item)
                let editTask = this.tasks.find(x => x.id == item.id)
                this.$store.commit('EDIT_TASK', editTask)
            },
            deleteTask(item) {
                console.log('DELETE TASK CLICKED', item)
                if (this.$p < 40 && item.createdBy.id !== this.$store.state.user.user.id){
                    return this.$message.error('You do not have permission to delete Tasks')
                }
                let self = this
                this.$confirm({
                    title: "Delete",
                    content: h => <div>Do you want to delete this Task?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.$api.delete(`/tasks/:instance/${item.id}`).then( ({data}) => {
                            console.log('just delete', data)
                            self.$store.commit('DELETE_TASK', data)
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
            searchAssign(input){
                if(input && input.length>1) {
                    this.users.forEach(x => {
                        x.name = x.firstName+ ' ' + x.lastName
                    })
                    this.userSource = this.users.filter(({name}) => {
                        name = name.toLowerCase()
                        input = input.toLowerCase()
                        return name.includes(input)
                    })
                    console.log('THIS USERSOURCEE AFTER FILTER', this.userSource)
                } else {
                    return this.userSource = []
                }
            },
            convertDate(num){
                let x = new Date(num)
                //Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
                let year = x.getFullYear()
                let month = x.getMonth()+1
                let day = x.getDate()
				if (this.dateFormat == 'YYYY/MM/DD') return year+'/'+month+'/'+day
				else if (this.dateFormat == 'DD/MM/YYYY') return day+'/'+month+'/'+year
				else {
					return month+'/'+day+'/'+year
				}
            },
            updatePerson(contact){
                console.log('UPDATE PERSON RAN', contact)
                this.expandedOpportunity.contact = contact
            },
            async updateWon(obj,note){
                console.log('UPDATE WON RAN', obj, note)
                let opportunity = JSON.parse(JSON.stringify(obj))
                let newNote = JSON.parse(JSON.stringify(note))
                opportunity.stage = 'won'
                opportunity.probability = 100

                if (note.subject || note.content){
                    console.log('NOTE HAS SUBJECT OR CONTENT')
                    await this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,newNote).then( ({data}) => {
                        this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                        opportunity = data
                        opportunity.stage = 'won'
                        opportunity.probability = 100
                    }).catch( err => {
                        console.log('ERORR', err)
                        if (err.response.data.error == 'Not Found'){
                            this.$message.error('Opportunity Not Found!')
                        }
                    })
                }
                this.$api.put(`/opportunities/:instance/${opportunity.id}`,opportunity).then(async ({data}) => {
                    console.log('DATAA COMING BACK', data)
                    this.$store.commit('UPDATE_OPPORTUNITY', data)

                    await this.$api.post(`/timelines/:instance/opportunities/${opportunity.id}`, {date: new Date().getTime(), logNote:'This opportunity was marked as Won'})
                    // let index = this.opportunities.findIndex(item => item.id == data.id)
                    // console.log('OPPORTUNITY FOUNDD', index)
                    // this.opportunities[index].stage = 'won'
                    // this.opportunities[index].closeDate = data.closeDate
                    this.closeLostWon()
                    this.refresh = Date.now()
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            async updateLost(obj){
                console.log('UPDATE LOST RAN', obj)
                let opportunity = JSON.parse(JSON.stringify(obj))
                opportunity.stage = 'lost'
                opportunity.probability = 0

                let sendObj = {}
                if (opportunity.reason) {
                    let find = this.lostReasons.find(x => x.id == opportunity.reason)
                    let note = {
                        subject:'Lost Opportunity',
                        content:`Lost Reason: ${find.name}`
                    }
                    await this.$api.put(`/opportunities/:instance/${opportunity.id}/note`,note).then(({data}) => {
                        this.$store.commit('UPDATE_OPPORTUNITY_NOTES', data)
                        sendObj = data
                        sendObj.stage = 'lost'
                        sendObj.probability = 0
                    }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					})
                }
                else {
                    sendObj = opportunity
                }

                this.$api.put(`/opportunities/:instance/${opportunity.id}`,sendObj).then(async ({data}) => {
                    console.log('DATAA COMING BACK', data)
                    this.$store.commit('UPDATE_OPPORTUNITY', data)

                    this.$api.post(`/timelines/:instance/opportunities/${data.id}`, {date: new Date().getTime(), logNote:'This opportunity was marked as Lost'})
                    // let index = this.opportunities.findIndex(item => item.id == data.id)
                    // console.log('OPPORTUNITY FOUNDD', index)
                    // this.opportunities[index].stage = 'lost'
                    // this.opportunities[index].reason = data.reason
                    // this.opportunities[index].closeDate = data.closeDate
                    this.closeLostWon()
                    this.refresh = Date.now()
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            closeLostWon(){
                this.lostWon = {
                    type:'',
                    visible:false,
                    opportunity:{}
                }
            },
            markWon(obj){
                console.log('MARK WON CLICKED', obj)
                this.lostWon = {
                    type:'won',
                    visible: true,
                    opportunity: obj && JSON.parse(JSON.stringify(obj))
                }
            },
            markLost(obj){
                console.log('MARK LOST CLICKED', obj)
                this.lostWon = {
                    type:'lost',
                    visible: true,
                    opportunity: obj && JSON.parse(JSON.stringify(obj))
                }
            },
            markArchive(obj){
                console.log('MARK ARCHIVE CLICKED', obj)
                this.lostWon = {
                    type:'archive',
                    visible:true,
                    opportunity: obj && JSON.parse(JSON.stringify(obj))
                }
            },
            saveInformation(){
                console.log('SAVE INFORMATION RAN')
                this.$refs.ruleForm.validate(async valid => {
                    if (valid) {
                        this.saveLoading = true
                        let sendObj = JSON.parse(JSON.stringify(this.expandedOpportunity))
                        sendObj.closeDate = this.moment(sendObj.closeDate).format('X')*1000
                        console.log('SEND OBJ', sendObj)
                        this.$api.put(`/opportunities/:instance/${sendObj.id}`, sendObj).then( ({data}) => {
                            console.log('DATAAAAA COMING BACK', data)
                            let index = this.opportunities.findIndex(item => item.id == data.id)
                            this.opportunities[index] = data
                            this.expandedOpportunity = {}
                            this.refresh = Date.now()
                            this.saveLoading = false
                            this.$store.commit('CLOSE_EXPAND_OPPORTUNITY')
                        }).catch(err => {
							this.saveLoading = false
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            displayPhone(num){
				if(!num) return '';
                let phone = num.toString().trim("").replace(/[^a-zA-Z0-9 ]/g, '')
                let first = phone.substring(0,3)
                let middle = phone.substring(3,6)
                let last = phone.substring(6,10)

                return '('+first+') '+middle+' '+last
            },
            startDrag: (evt, item) => {
                console.log('START DRAG RAN', item)
                evt.dataTransfer.dropEffect = 'move'
                evt.dataTransfer.effectAllowed = 'move'
                evt.dataTransfer.setData('itemID', [item.id,item.stage])
            },
            onDrop (evt, list) {
                console.log('ON DROP RAN', evt, list)
                const itemID = evt.dataTransfer.getData('itemID')
                let newItemID = itemID
                newItemID = newItemID.split(',')
                const item = this.opportunities.find(item => item.id == newItemID[0])
                console.log('NEWITEMID', newItemID)
                if (newItemID[1] == list.toString()) {
                    console.log('same column')
                }

                if (newItemID[1] == 'won' || newItemID[1] == 'transaction'){
                    console.log('ITEM WAS WON')
                    item.stage = newItemID[1]
                } else {
                    console.log('ITEM WAS NOT WON OR LOST OR IN TRANSACTION')
                    if (list == 'won' || list == 'lost' || list == 'transaction'){
                        if (list == 'won'){
                            this.markWon(item)
                        } else if (list == 'lost') {
                            if (newItemID[1] != 'lost'){
                                this.markLost(item)
                            }
                        }
                    } else {
                        console.log('POSTING NEW OPPORTUNITY INFORMATION')
                        let sendObj = JSON.parse(JSON.stringify(item))
                        sendObj.stage = list
                        let find = this.stages.find(x => x.id == list)
                        if (find && find.probability) sendObj.probability = find.probability
                        console.log('sendOjb', sendObj)
                        this.$api.put(`/opportunities/:instance/${sendObj.id}`,sendObj).then( async ({data}) => {
                            if (find && find.name) {
                                await this.$api.post(`/timelines/:instance/opportunities/${data.id}`, {date: new Date().getTime(), logNote:`This opportunity was moved to ${find.name}`})
                            }
                            console.log('DATAA COMING BACK', data, find)
                            item.stage = list
                            this.$store.commit('UPDATE_OPPORTUNITY', data)
                            this.refresh = Date.now()

                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						})
                    }
                }

            },
            showMenu(item){
                this.activeTab = item
            },
            textTime(date){
                let d = new Date(date.date)
                return d.toString().substring(16,21)
            },
            textDate(date){
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                let month = monthNames[Number(date.substring(0,2))-1]
                return month + ' ' + date.substring(3,5)
            },
            sortDates(date){
                let dates = []
                this.events.forEach( x => {
                    if (date == (`${x.date.substring(5,7)}` + '/' + `${x.date.substring(8,10)}` + '/' + `${x.date.substring(0,4)}`)){
                        dates.push(x)
                    }
                })
                dates.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date)
                })
                return dates
            },
            cancelEditAppointment(){
                this.$store.commit('CLOSE_APPOINTMENT')
            },
            cancelEdit(){
                this.$store.commit('CLOSE_EDIT_TASK')
            },
        },
        mounted(){
			this.refresh = this.update()
        },
        created() {
            this.$store.commit('SIDEBAR_SELECTION', {value:'Sales Pipeline'})

        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>

    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:5%;
        }
    }
    .aB{
        align-items: baseline;
    }
    .pipelineColumns {
        background-color: #FAF9F9;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 24px;
        width:380px;
    }
    .edit-delete {
        padding: 9px 0;
		cursor: pointer;
		color: #9EA0A5;
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
    }
    .popoverContent{
        height: 35px;
        width: 150px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .smallIcon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        text-align: center;
        padding: 7px 0;
    }

</style>

<style lang="scss">
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .popover-content-style .ant-popover-inner-content{
        cursor: pointer;
    }
</style>
